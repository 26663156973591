import React from 'react';
import s from './FaqsPageContainer.module.scss';
import Collapse from './Collapse';
import arrow_up from '../images/icons/up-arrow-blue.svg';
import arrow_down from '../images/icons/down-arrow-blue.svg';


class FaqsPageContainer extends React.Component {

    state = {
        collapse: false,
    }
    componentDidMount = () => {
       document.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false)
    }

    handleCollapse = (e) => {
        e.preventDefault();
        this.setState({
            collapse: !this.state.collapse
        })
    }

    handleClick = (e) =>{
        if(!this.node.contains(e.target)){
            this.setState({
                collapse:false
            })
        }
    }
    
    render(){
        const { productType, faqs, title, icon, showAll=false } = this.props;
        const { collapse } = this.state;
        return(
            <div className={s.faqsContainer} ref={node => this.node = node}>
                <div className={s.faqsPageContainer} >
                    <div className={s.collapseItem} onClick={this.handleCollapse} onKeyDown={this.handleCollapse} role="button" tabIndex="0">
                        <div className={s.iconContainer}>
                            <img  className={s.wallet} src={icon} alt='wallet'/>
                            <h3 className={s.faqsTitle}>{title}</h3>
                        </div>
                        {(collapse) ? <img className={s.arrowIcon} src={arrow_up} alt='arrow_up'/> : <img className={s.arrowIcon} src={arrow_down} alt='arrow_down'/>}
                    </div>
                    <div className={(collapse) ? `${s.openElement} collapse` : s.collapseElement}>
                        <div className={s.collapsesContainer}>
                            {faqs.map((faq, index) =>
                                (faq.productType === productType || (showAll && faq.productType === "All")) && 
                                <Collapse title={faq.title} key={index} style={(index === faqs.length -1) ? {borderBottom: '0px'} : null}>
                                    {faq.description.html}
                                </Collapse>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqsPageContainer;
