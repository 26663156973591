import React from "react";
import "../style/style.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import s from '../style/Faqs.module.scss';
import NavBar from '../components/NavBar';
import Experts from '../components/Experts';
import CTAFooter from '../components/CTAFooter';
import Footer from '../components/Footer';
import FaqsPageContainer from '../components/FaqsPageContainer';
import wallet from "../images/icons/icono-wallet.svg";
import homeIcon from '../images/icons/icono-HE.svg';
import carIcon from '../images/icons/icono-CE.svg';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';


const Faqs = () => {
    const faqs = useStaticQuery(graphql`{
        gcms { 
            faqs (where: {status: PUBLISHED}) {
                id
                title
                productType
                description { 
                    html
                }
            }
        }
    }`).gcms.faqs;
    return(
        <div> 
                <MetaData/>
                <TagManager/>
                <PageView/>
                <div className={s.headerContainer}>
                    <NavBar blue={true}/>
                </div>
                <div className={s.faqsBody}>
                    <h2 className={s.title}>Encontrá las respuestas a las preguntas más frecuentes</h2>
                    <FaqsPageContainer productType={'All'} showAll={true} faqs={faqs} title='¿Qué es Agilis?' icon={wallet}/>
                    <FaqsPageContainer productType={'Home'} faqs={faqs} title='Créditos con garantía Hipotecaria' icon={homeIcon}/>
                    <FaqsPageContainer productType={'Car'} faqs={faqs} title='Créditos con garantía Vehicular' icon={carIcon}/>
                </div>
                <Experts/>
                <CTAFooter blue={true}>
                    Consultá tu crédito sin compromiso
                </CTAFooter>
                <Footer/>
                <TagManagerFooter/>
        </div>
    )
}

export default Faqs
